@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --MainFont: "Anton", sans-serif;
  --SecondFont: "Montserrat", sans-serif;
  --MainColor: #be0d1e;
  --secondColor: #1384ef;
  --baseColor: #000;
  --diffBase: rgba(0, 0, 0, 0.7);
  --grey: rgba(255, 255, 255, 0.05);
  --diffWhite: rgba(255, 255, 255, 0.5);
  --nextui-background: 0 0% 0%
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: var(--MainFont);
  background-color: var(--baseColor);
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--MainColor);
}
::-webkit-scrollbar-track {
  background-color: transparent;
}


@media (max-width: 375px) {
  .sm-ultraSmall {
    font-size: 0.6rem;
  }

  .sm-p-1 {
    padding: 0.3em !important;
  }
  
}

@media (max-width: 768px) {

  .mb-h-max-200 {
    max-height: 200px;
  }

  .mb-gap-4 {
    gap: 1em;
  }
  .mb-full {
    width: 100%;
  }

  .mb-sec-p {
    padding: 0 2.5em;
  }

  .mb-flex-col {
    flex-direction: column;
  }

  .mb-mt-12 {
    margin-top: 3em;
  }

  .mb-hide {
    display: none;
  }

  .mb-text-center {
    text-align: center;
  }

  .mb-jus-center {
    justify-content: center;
  }

  .mb-p-4 {
    padding: 1em;
  }

  .mb-p-8 {
    padding: 2em;
  }
}

.z-1 {
  z-index: 1;
}

.z-110 {
  z-index: 110;
}

.light {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

.mainFont {
  font-family: var(--SecondFont);
}

.secondFont {
  font-family: var(--SecondFont);
}

.small {
  font-size: 0.8rem;
}

.subtitle {
  font-size: 1.2rem;
}

.ultraSmall {
  font-size: 0.6rem;
}

.mainColor {
  color: var(--MainColor);
}

.secondColor {
  color: var(--secondColor);
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.whatsApp {
  color: #25d366;
}

.diffWhite {
  color: var(--diffWhite);
}

.diffBase {
  color: var(--diffBase);
}

.mainBg {
  background-color: var(--MainColor);
}

.secondBg {
  background-color: var(--secondColor);
}

.diffBg{
  background-color: var(--grey);
}

.baseBg {
  background-color: var(--baseColor);
}



.danger {
  color: #ff0000;
}

.whiteBg {
  background-color: #fff;
}

.mainBor {
  border: 1px solid var(--MainColor);
}

.secondBor {
  border: 1px solid var(--secondColor);
}

.baseBor {
  border: 1px solid var(--baseColor);
}


.homeBg {
  background-image: url("../public/home.webp");
}


.aboutBg {
  background-image: url("../public/about.webp");
}


/* SHINE EFFECT */

.shineBor {
  transition: all .3s ease-in-out;
}

.shineBor:hover {
  box-shadow: 0 0 10px 0 var(--MainColor);
}